//          web隐藏
// &^%ugvjt75HGJ6yR$#@$#fy
// v108

// <!--        web屏蔽-->
import { Toast } from 'vant'
import { createApp } from 'vue'
import App from '@/App'
const app = createApp(App)
export const globals = async () => app.config.globalProperties

globals.$isWeb = true
// import Vue from 'vue'

let domain
const changeLine = localStorage.getItem('changeLine')
export const getJson = (url, time) => {
  const request = new XMLHttpRequest()
  const timestamp = new Date().getTime()
  // APP1
  request.open('GET', 'https://huijuapp.oss-cn-hongkong.aliyuncs.com/app.json?v=' + timestamp, false)
  // APP2
  // request.open('GET', 'https://zlcapp2spare.oss-cn-hongkong.aliyuncs.com/zlc2.json?v=' + timestamp, false)
  if (navigator.onLine) { // 有网络
    request.send(null)
    if (time === 2 && request.status !== 200) {
      Toast.fail('连接错误，请重试！')
      return
    }
    if (request.status === 200) {
      localStorage.setItem('nowLineUrl', JSON.parse(request.responseText).url)
      localStorage.setItem('serveUrl', JSON.parse(request.responseText).customer_service_link)
      localStorage.setItem('api_url', JSON.stringify(JSON.parse(request.responseText).api_url))
      if (changeLine) {
        domain = localStorage.getItem('changeLine')
      } else {
        domain = localStorage.getItem('nowLineUrl')
      }
    }
  }
}

if (globals.$isWeb) {
  localStorage.removeItem('nowLineUrl')
  localStorage.removeItem('serveUrl')
  localStorage.removeItem('api_url')
  localStorage.removeItem('changeLine')
  if (location.port) {
    domain = location.protocol + '//' + document.domain + ':' + location.port
  } else {
    domain = location.protocol + '//' + document.domain
  }
  // domain = 'https://www.vngather.vip'
  domain = 'https://ht.oumeng.lol'
} else {
  // domain = localStorage.getItem('nowLineUrl')
  getJson()
}
globals.$imgUrl = domain
export default {
  testUrl: domain
}
